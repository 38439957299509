import dynamic from "next/dynamic"
import { memo } from "react"

import { ACFGenericBlock } from "@/lib/models/blocks"
import {
  isCtaBlock,
  isFaqBlock,
  isFullImageBlock,
  isHeaderAlignBlock,
  isHeaderKontaktBlock,
  isHeaderTwoColBlock,
  isHeaderTwoColSmallBlock,
  isHeroBlock,
  isHeroHomeBlock,
  isHomeServicesBlock,
  isJobListingsBlock,
  isLargeTextCenterBlock,
  isLogoWallBlock,
  isLongContentBlock,
  isProcessBlock,
  isPublicationsBlock,
  isRelatedArticlesBlock,
  isServiceBlock,
  isTestimonialBlock,
  isThreeColsBlock,
  isTwoColumnContentBlock,
  isWaveSeparatorBlock,
} from "@/lib/utils/type-guards"

import ErrorBoundary from "../shared/error-boundary"
import BlockLoadingFallback from "./loading-fallback"

/**
 * Dynamically import blocks to reduce initial bundle size.
 * Some pages may not need all blocks, so we can load them on demand.
 *
 * @see https://nextjs.org/docs/advanced-features/dynamic-import
 */
const DynamicHero = dynamic(() => import("./hero"), { loading: BlockLoadingFallback })
const DynamicHeroHome = dynamic(() => import("./hero-home"), { loading: BlockLoadingFallback })
const DynamicHeaderAlign = dynamic(() => import("./header/align"), {
  loading: BlockLoadingFallback,
})
const DynamicHeaderContact = dynamic(() => import("./header/contact"), {
  loading: BlockLoadingFallback,
})
const DynamicHeaderTwoCol = dynamic(() => import("./header/two-col"), {
  loading: BlockLoadingFallback,
})
const DynamicHeaderTwoColSmall = dynamic(() => import("./header/two-col-small"), {
  loading: BlockLoadingFallback,
})
const DynamicCtaBlock = dynamic(() => import("./cta-block"), { loading: BlockLoadingFallback })
const DynamicPublications = dynamic(() => import("./publications/Publications"), {
  loading: BlockLoadingFallback,
})
const DynamicTestimonialSlider = dynamic(() => import("./testimonialSlider"), {
  loading: BlockLoadingFallback,
})
const DynamicRelatedArticles = dynamic(() => import("./related-articles/related-articles"), {
  loading: BlockLoadingFallback,
})
const DynamicFaqBlock = dynamic(() => import("./faq-block"), { loading: BlockLoadingFallback })
const DynamicWaveSeparator = dynamic(() => import("./wave-separator/wave-separator"), {
  loading: BlockLoadingFallback,
})
const DynamicThreeColumnsBlock = dynamic(() => import("./three-columns"), {
  loading: BlockLoadingFallback,
})
const DynamicLogoWall = dynamic(() => import("./logo-wall/logo-wall"), {
  loading: BlockLoadingFallback,
})
const DynamicLargeTextCenter = dynamic(() => import("./large-text-center"), {
  loading: BlockLoadingFallback,
})
const DynamicTwoColumnContent = dynamic(() => import("./two-column-content/two-column-content"), {
  loading: BlockLoadingFallback,
})
const DynamicProcess = dynamic(() => import("./process"), { loading: BlockLoadingFallback })
const DynamicJobs = dynamic(() => import("./jobs/jobs"), { loading: BlockLoadingFallback })
const DynamicFullImage = dynamic(() => import("./full-image"), { loading: BlockLoadingFallback })
const DynamicLongContent = dynamic(() => import("./long-content"), {
  loading: BlockLoadingFallback,
})
const DynamicHomeServices = dynamic(() => import("./home-services"), {
  loading: BlockLoadingFallback,
})
const DynamicServiceBubble = dynamic(() => import("./service-bubble"), {
  loading: BlockLoadingFallback,
})

export const AcfBlocksContainer = memo(function BlocksContainer({
  blocks,
}: {
  blocks: ACFGenericBlock[]
}) {
  const _blocks = Array.isArray(blocks) ? blocks.map(differentiator) : null
  return <>{_blocks}</>
})

const differentiator = (block: ACFGenericBlock) => {
  if (isHeroBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/heroblock]">
        <DynamicHero {...block} />
      </ErrorBoundary>
    )

  if (isHeroHomeBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/hero-home]">
        <DynamicHeroHome {...block} />
      </ErrorBoundary>
    )

  if (isPublicationsBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/publications]">
        <DynamicPublications {...block} />
      </ErrorBoundary>
    )

  if (isTestimonialBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/testimonial]">
        <DynamicTestimonialSlider {...block} />
      </ErrorBoundary>
    )

  if (isRelatedArticlesBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/related-articles]">
        <DynamicRelatedArticles {...block} />
      </ErrorBoundary>
    )

  if (isFaqBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/faq]">
        <DynamicFaqBlock {...block} />
      </ErrorBoundary>
    )

  if (isCtaBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/cta]">
        <DynamicCtaBlock {...block} />
      </ErrorBoundary>
    )

  if (isWaveSeparatorBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/wave]">
        <DynamicWaveSeparator {...block} />
      </ErrorBoundary>
    )

  if (isThreeColsBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/three-cols]">
        <DynamicThreeColumnsBlock {...block} />
      </ErrorBoundary>
    )

  if (isLogoWallBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/logo-wall]">
        <DynamicLogoWall {...block} />
      </ErrorBoundary>
    )

  if (isLargeTextCenterBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/large-text-center]">
        <DynamicLargeTextCenter {...block} />
      </ErrorBoundary>
    )

  if (isTwoColumnContentBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/two-cols-content]">
        <DynamicTwoColumnContent {...block} />
      </ErrorBoundary>
    )

  if (isProcessBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/process]">
        <DynamicProcess {...block} />
      </ErrorBoundary>
    )

  if (isJobListingsBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/job-listings]">
        <DynamicJobs {...block} />
      </ErrorBoundary>
    )

  if (isFullImageBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/full-image]">
        <DynamicFullImage {...block} />
      </ErrorBoundary>
    )

  if (isHeaderAlignBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/header-align]">
        <DynamicHeaderAlign {...block} />
      </ErrorBoundary>
    )

  if (isHeaderKontaktBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/header-contact]">
        <DynamicHeaderContact {...block} />
      </ErrorBoundary>
    )

  if (isLongContentBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/long-content]">
        <DynamicLongContent {...block} />
      </ErrorBoundary>
    )

  if (isHeaderTwoColBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/header-two-col]">
        <DynamicHeaderTwoCol {...block} />
      </ErrorBoundary>
    )

  if (isHeaderTwoColSmallBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/header-two-col-small]">
        <DynamicHeaderTwoColSmall {...block} />
      </ErrorBoundary>
    )

  if (isHomeServicesBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/home-services]">
        <DynamicHomeServices {...block} />
      </ErrorBoundary>
    )

  if (isServiceBlock(block))
    return (
      <ErrorBoundary key={block.id} name="[acf/service]">
        <DynamicServiceBubble {...block} />
      </ErrorBoundary>
    )
}
