/**
 * ACF blocks type guards
 */
import {
  CTABlock,
  FAQBlock,
  FullImageBlock,
  HeaderAlignBlock,
  HeaderKontaktBlock,
  HeaderTwoColBlock,
  HeaderTwoColsSmallBlock,
  HeroBlock,
  HeroHomeBlock,
  HomeServicesBlock,
  JobListingsBlock,
  LargeTextCenterBlock,
  LogoWallBlock,
  LongContentBlock,
  ProcessBlock,
  PublicationsBlock,
  RelatedArticlesBlock,
  ServiceBlock,
  TestimonialBlock,
  ThreeColsBlock,
  TwoColumnContentBlock,
  WaveSeparatorBlock,
} from "@/lib/models/blocks"

export const isHeroBlock = (object: any): object is HeroBlock =>
  typeof object === "object" && object !== null && object.name === "acf/heroblock"

export const isPublicationsBlock = (object: any): object is PublicationsBlock =>
  typeof object === "object" && object !== null && object.name === "acf/publications"

export const isTestimonialBlock = (object: any): object is TestimonialBlock =>
  typeof object === "object" && object !== null && object.name === "acf/testimonial"

export const isRelatedArticlesBlock = (object: any): object is RelatedArticlesBlock =>
  typeof object === "object" && object !== null && object.name === "acf/related-articles"

export const isFaqBlock = (object: any): object is FAQBlock =>
  typeof object === "object" && object !== null && object.name === "acf/faq"

export const isCtaBlock = (object: any): object is CTABlock =>
  typeof object === "object" && object !== null && object.name === "acf/cta"

export const isWaveSeparatorBlock = (object: any): object is WaveSeparatorBlock =>
  typeof object === "object" && object !== null && object.name === "acf/wave"

export const isThreeColsBlock = (object: any): object is ThreeColsBlock =>
  typeof object === "object" && object !== null && object.name === "acf/three-cols"

export const isLogoWallBlock = (object: any): object is LogoWallBlock =>
  typeof object === "object" && object !== null && object.name === "acf/logo-wall"

export const isLargeTextCenterBlock = (object: any): object is LargeTextCenterBlock =>
  typeof object === "object" && object !== null && object.name === "acf/large-text-center"

export const isTwoColumnContentBlock = (object: any): object is TwoColumnContentBlock =>
  typeof object === "object" && object !== null && object.name === "acf/two-cols-content"

export const isProcessBlock = (object: any): object is ProcessBlock =>
  typeof object === "object" && object !== null && object.name === "acf/process"

export const isJobListingsBlock = (object: any): object is JobListingsBlock =>
  typeof object === "object" && object !== null && object.name === "acf/job-listings"

export const isFullImageBlock = (object: any): object is FullImageBlock =>
  typeof object === "object" && object !== null && object.name === "acf/full-image"

export const isHeaderAlignBlock = (object: any): object is HeaderAlignBlock =>
  typeof object === "object" && object !== null && object.name === "acf/header-align"

export const isHeaderKontaktBlock = (object: any): object is HeaderKontaktBlock =>
  typeof object === "object" && object !== null && object.name === "acf/header-contact"

export const isLongContentBlock = (object: any): object is LongContentBlock =>
  typeof object === "object" && object !== null && object.name === "acf/long-content"

export const isHeroHomeBlock = (object: any): object is HeroHomeBlock =>
  typeof object === "object" && object !== null && object.name === "acf/hero-home"

export const isHeaderTwoColBlock = (object: any): object is HeaderTwoColBlock =>
  typeof object === "object" && object !== null && object.name === "acf/header-two-col"

export const isHeaderTwoColSmallBlock = (object: any): object is HeaderTwoColsSmallBlock =>
  typeof object === "object" && object !== null && object.name === "acf/header-two-col-small"

export const isHomeServicesBlock = (object: any): object is HomeServicesBlock =>
  typeof object === "object" && object !== null && object.name === "acf/home-services"

export const isServiceBlock = (object: any): object is ServiceBlock =>
  typeof object === "object" && object !== null && object.name === "acf/service"
