import { Component, ErrorInfo, ReactNode } from "react"

interface Props {
  name: string
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  public render() {
    if (this.state.hasError)
      return (
        <section className="m-5 flex h-32 items-center justify-center rounded-2xl bg-red-300 lg:m-20 lg:h-48 2xl:m-24">
          <h2 className="">Sorry, an error occurred with this block {this.props.name}</h2>
        </section>
      )

    return this.props.children
  }
}

export default ErrorBoundary
