import { AcfBlocksContainer } from "../components/acf-blocks/container"
import { Layout } from "../components/layout/Layout"
import { PageProps } from "../controllers/page"

export default function Page({ acfBlocks, imageMap, globalFields, seo }: PageProps) {
  return (
    <Layout globalFields={globalFields} imageMap={imageMap} seo={seo}>
      <AcfBlocksContainer blocks={acfBlocks} />
    </Layout>
  )
}
